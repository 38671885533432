import BaseButtonDanger from "@/components/Base/BaseButtonDanger.vue";
import BaseButtonDefault from "@/components/Base/BaseButtonDefault.vue";
import { baseModal, crewStatus, helperMixin, userProfilePDF } from "@/mixins";
import { defineComponent } from "vue";
import BaseModal from "@/components/Base/BaseModal.vue";
import BaseButtonPrimary from "@/components/Base/BaseButtonPrimary.vue";
import BaseFormGroup from "@/components/Base/BaseFormGroup.vue";
import { companyHttp } from "@/http/company";
import { crewHttp } from "@/http/crew";
import Loading from "@/components/Loading.vue";
import { toast } from "@/template/app";
export default defineComponent({
    props: {
        crewId: {
            required: true
        },
    },
    mixins: [helperMixin, baseModal, crewStatus, userProfilePDF],
    components: {
        Loading,
        BaseFormGroup,
        BaseModal,
        BaseButtonDefault,
        BaseButtonDanger,
        BaseButtonPrimary,
    },
    data() {
        return {
            submitting: false,
            errorFetch: "",
            errors: {},
            form: {
                company_id: "",
            },
            crew: null
        };
    },
    computed: {
        companyHttp,
    },
    methods: {
        async fetchCrew() {
            this.errorFetch = "";
            this.crew = null;
            const { status, message, response } = await crewHttp().read(this.crewId);
            if (status == 200) {
                this.crew = response.data;
            }
            else {
                this.errorFetch = message;
            }
        },
        async submit() {
            var _a;
            this.submitting = true;
            const { status, message, validationErrors } = await crewHttp().updateCrewCompany((_a = this.crew) === null || _a === void 0 ? void 0 : _a.id, this.form);
            if (status == 200) {
                this.$emit('saved');
                toast("success", message);
            }
            else if (status == 422) {
                this.errors = validationErrors !== null && validationErrors !== void 0 ? validationErrors : {};
            }
            else {
                toast("error", message);
            }
            this.submitting = false;
        }
    },
});

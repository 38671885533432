import { filterHelper, helperMixin, infinityScrollTable, positionFilter } from "@/mixins";
import { defineComponent } from "vue";
import sailorHttp from "@/http/sailor";
import ActionTableColumn from "@/components/Column/ActionTableColumn.vue";
import BaseTable from "@/components/Base/BaseTable.vue";
import BaseTableRow from "@/components/Base/BaseTableRow.vue";
import BaseTableColumn from "@/components/Base/BaseTableColumn.vue";
import AvatarImg from "@/components/AvatarImg.vue";
import DropdownItem from "@/components/Dropdown/DropdownItem.vue";
import FilterModalContent from "@/components/FilterModalContent.vue";
import companyHttp from "@/http/company";
import BaseFormGroup from "@/components/Base/BaseFormGroup.vue";
import BaseInput from "@/components/Base/BaseInput.vue";
import ChangeCompanyModal from "@/components/Sailor/ChangeCompanyModal.vue";
export default defineComponent({
    components: {
        ChangeCompanyModal,
        ActionTableColumn,
        BaseTable,
        BaseTableRow,
        BaseTableColumn,
        AvatarImg,
        DropdownItem,
        FilterModalContent,
        BaseFormGroup,
        BaseInput
    },
    mixins: [
        helperMixin,
        filterHelper(),
        positionFilter(),
        infinityScrollTable(sailorHttp(), {
            position_in: []
        })
    ],
    data() {
        return {
            showChangeCompanyModal: false,
        };
    },
    computed: {
        companyHttp,
    },
    methods: {
        changeCompany(row) {
            this.selectedRow = row;
            this.showChangeCompanyModal = true;
        }
    }
});
